@import '../../../style/variables';

.customer-menu {
  .menu {
    &__list {
      display: flex;
    }

    &__btn, &__btn_submenu {
      color: #6E6E73;
    }

    &__btn {
      &_loader {
        display: block;
        margin: auto;
        width: 50px;
        padding: 3px 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__item, &__item_submenu {
      position: relative;
      text-align: center;
      width: 100px;
      padding: 10px 0;
      font-size: 12px;
    }

    &__item {
      &:hover {
        .menu__btn {
          color: $coal;
        }
      }
    }

    &__item_round {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
    }

    &__item_submenu {
      &:hover {
        .menu__btn_submenu {
          color: $coal;
        }
      }
    }



    &__dropdown {
      top: 39px;
      position: absolute;
      z-index: 9999;
      min-width: 100px;
      padding: 10px 0;
      list-style: none;
      background-color: #F8F9F9;
      display: none;

      &.show {
        display: block;
      }
    }
  }

  .menu__btn_round {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

    font-size: 12px;
    border: 1px solid $dark-grey;
    border-radius: 15px;
    width: 70px;
    height: 30px;
    background-color: $white;
  }
}