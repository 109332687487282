@import '../../style/variables';


.indicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: inset 0 1px 3px rgba(85, 84, 84, 0.5);
  background-color: $medium-grey;

  &.green {
    background-color: $green;
  }

  &.red {
    background-color: $alert;
  }
}