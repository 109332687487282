@import '../../style/variables';

.notifications {
  &__table-container {
    margin: 0 auto;
    width: 1008px;
    height: 738px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1008px;
    margin-bottom: 30px;
    h1 {
      text-align: center;
      margin: 0 auto;
    }
    button {
    }
  }

  &__table {
    height: 738px;
    &.table {
      padding-left: 20px;
      display: grid;
      overflow: auto;
      background-color: $white;
      border: 1px solid #F8F9F9;
      font-size: 14px;
      margin-bottom: 20px;
      padding-bottom: 30px;
      border-radius: 15px;
      border-collapse: collapse;
      min-width: 100%;
      min-height: 100%;
      grid-column-gap: 20px;
      grid-template-columns:
        minmax(30px, 30px)
        minmax(240px, 240px)
        minmax(100px, 100px)
        minmax(100px, 100px)
        minmax(274px, 274px)
        minmax(100px, 100px);
      grid-template-rows: 40px;
      grid-auto-rows: 30px;


      thead,
      tbody,
      tr {
        display: contents;
      }

      td {
        overflow: hidden;
        white-space: nowrap;
      }

      th, td {
        &:nth-child(1) {
          text-align: end;
        }
        &:nth-child(3),&:nth-child(4) {
          text-align: center;
        }
        &:nth-child(6) {
          text-align: end;
        }
      }

      //td {
      //  &:nth-child(3) {
      //    cursor: pointer;
      //    color: $link;
      //  }
      //  &:nth-child(4) {
      //    cursor: pointer;
      //    color: $link;
      //  }
      //}

      th {
        font-weight: 500;
        text-align: left;
        position: sticky;
        padding-top: 12px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

  }

  .unread {
    margin-top: 3px;
    width: 10px;
    height: 10px;
    background-color: $link;
    border-radius: 50%;
    box-shadow: inset 0px 1px 3px rgba(85, 84, 84, 0.5);
  }

}
