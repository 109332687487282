.applicant-card {
  & .form {
    &__input {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  &__btn-block {
    display: flex;
    justify-content: space-between;
    width: 210px;
    margin: 0 auto;
  }

  &__btn {
    &.button {
      width: 100px;
    }
  }
}