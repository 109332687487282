@import '../../style/variables';

.btn-group {
  display: flex;
  justify-content: space-between;
  width: 210px;

  button {
    width: 100px;
  }

  &__loader {
    display: block;
    margin: auto;
    width: 64px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__success {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $dark-grey;
  }

}