@import '../../style/variables';

:root {
  --rdp-cell-size: 42.5px;
  --rdp-accent-color: #167ffc;
  --rdp-background-color: #f8f7fa;
  --rdp-accent-color-dark: #167ffc;
  --rdp-background-color-dark: #167ffc;
}

.rdp {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: $description;
  margin: 0;

  &-day {
    color: $coal;
    font-weight: 500;

    &_range_end.rdp-day_range_start {
      border-radius: 5px;
    }

    &:hover {
      background-color: $light-grey;
    }

    &_outside {
      color: $grey;
    }

    &_selected {
      color: $coal;
      background-color: $blue;
    }

    &_range_start:not([disabled]), &_range_end:not([disabled]) {
      background-color: $dark-grey;
      border-radius: 5px 0 0 5px;
    }

    .rdp-button:focus:not([disabled]), .rdp-button:active:not([disabled]) {
      border: none;
    }

    &_range_end:not([disabled]) {
      border-radius: 0 5px 5px 0;
    }

    &_range_middle:not([disabled]) {
      background-color: $light-grey;
      color: $coal;
    }
  }

  &-month {
    &.rdp-caption_start {
      position: relative;

      &:after {
        content: '';
        height: 110%;
        border-right: solid 1px #eeedf0;
        position: absolute;
        right: -8%;
        top: -0%;
        z-index: 1;
      }
    }
  }

  &-months {
    border-bottom: 1px solid #eeedf0;
  }
}

.rdp-button:active:not([disabled]),
.rdp-button:focus:not([disabled]) {
  border: none;
}

.rdp-caption_label {
  color: $coal;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.rdp-day_selected:focus:not([disabled]),
.rdp-day_selected:active:not([disabled]),
.rdp-day_selected:hover:not([disabled]) {
  background-color: $dark-grey;

}


.date-picker .rdp-button:hover:not([disabled]) {
  background-color: $dark-grey;
}

.date-picker {
  //height: 412px;
  height: 452px;
  width: 865px;
  display: flex;
  background-color: white;
  border-radius: 15px;
  padding: 30px 0 16px 0;

  .btn-block, .input-block, .bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-wrapper {
      width: 120px;
    }
  }

  .bottom-block {
    margin-right: 22px;
    margin-top: 15px;
    margin-left: 20px;
  }

  .input-block {
    width: 294px;

    .arrow {
      display: block;
      width: 25px;
      height: 25px;

      .img {
        width: 100%;
      }
    }
  }

  .btn-block {
    width: 315px;
  }



  & .ranges {
    height: 352px;
    font-size: $description;
    font-weight: 500;
    border-right: 1px #eeedf0 solid;
    width: 170px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & button {
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    & li {
      padding: 4px 0;
      width: 100%;
      padding-left: 23px;

      &:hover {
        background-color: $light-grey;
        color: $blue;
      }

      &.active {
        background-color: $dark-grey;

        & button {
          color: $white;
        }
      }
    }
  }

  .rdp-head_cell {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }


  .rdp-multiple_months .rdp-caption {
    margin-bottom: 15px;
  }

  .rdp-nav_button {
    border-radius: 5px;
  }


  .rdp-button:hover:not([disabled]) {
    background-color: $light-grey;
  }


  .rdp-day_range_middle:not([disabled]) {
    background-color: $light-grey;
  }

  .rdp-months {
    display: flex;
    justify-content: space-between;
    height: 355.5px;
    padding: 0 25px;
    padding-bottom: 26px;
  }

  .rdp-month {
    padding: 0;
    margin: 0;
  }

  & .main-container {
    margin-top: 4px;
    width: 80%;

    & input {
      font-family: 'Poppins', sans-serif;
    }

    & button {
      svg {
        width: 10px;
        height: 10px;
      }

      & + button {
        margin-left: 8px;
      }
    }
  }
}

