.comms-page {
  &__email-container {
    h1 {
      text-align: center;
    }
    .comms-group__field {
      width: 360px;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .editor {
      &__label {
        margin-left: 20px;
      }

      .ProseMirror {
        height: 375px;
        width: 400px;
      }
    }

  }
}