@import '../../../style/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  border-bottom: 1px solid $medium-grey;

  &__title {
    line-height: 39px;
  }
}