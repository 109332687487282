@import '../../../style/variables';

.creditrisk {
  width: 800px;
  height: 770px;

  &_block {
    column-gap: 40px;
    font-weight: 400;
    column-count: 2;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;

    &.disabled > p {
      color: $medium-grey;
    }
  }



  &__text {
    &.green {
      color: $green;
    }

    &.red {
      color: $alert;
    }

    &.grey {
      color: $medium-grey;
    }
  }
}