.modal {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 103px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  &__btn {
    margin: 0 auto;
  }

  &__loader {
    display: block;
    margin: 0 auto;
    width: 100px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}