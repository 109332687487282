.templates {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &__btn-block {
    width: 320px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }

  &__body {
    &_top {
      width: 360px;
      margin: 0 auto;
    }
  }

  &__subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__field {
    margin-bottom: 10px;
  }

  &__editor {
    margin-bottom: 60px;
    .ProseMirror {
      height: 375px;
      overflow: auto;
    }

  }

}

.templates-modal {
  &__subtitle {
    margin-bottom: 60px;
  }
  &__btn-block {
    width: 210px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    &_single {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}