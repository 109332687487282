.abandonment {
  display: flex;
  background-color: #FFFFFF;
  height: 36px;
  align-items: center;

  &__item {
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.line {
  border-bottom: 1px solid #C8CACB;
  padding-bottom: 33px;
  margin-bottom: 33px;
}

.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 3px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}