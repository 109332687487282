@import '../../style/variables';

.comms-modal {
  width: 400px;
  padding: 20px;
  border-radius: 15px;
  background-color: $white;

  .comms-group__body {
    margin: unset;
  }

  .title {
    text-align: left;
  }

  .comms-group__bottom-block {
    width: 100%;
  }

  .ProseMirror {
    border: 1px solid $grey;
    max-height: 218px;
    overflow: auto;
  }
}
