@import '../../style/variables';

.dialog-popup {
  width: 400px;
  height: 200px;
  background-color: $white;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;

  &__title {
    min-height: 26px;
    font-size: 26px;
    margin-bottom: 20px;
  }
}