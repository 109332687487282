@import '../../style/variables';

.fullscreen-enabled {
  .dashboard {
    background-color: $bg;
    padding: 90px;
    color: $coal;
    height: 100vh;

    &__card {
      height: 300px;
      max-height: 300px;
      overflow: hidden;

      .card {
        &__list {
          height: 90px;
          overflow:hidden;
        }
      }
    }

    &[data-theme='dark'] {
      background-color: $black;
      color: $white;
    }
  }
}

.dashboard {

  h1 {
    text-align: left;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__theme-block {
    display: flex;
    justify-content: space-between;
    width: 50px;
  }

  &__card {
    height: 262px;

    &.card {
      flex: 0 1 33.333%;

      .card__number {
        display: inline-block;
        font-weight: 600;
        font-size: 80px;
        margin-bottom: 20px;
      }

      .card__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 21px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__cards-block {
    display: flex;
    gap: 30px;
    margin-bottom: 60px;
  }
}