.ProseMirror  {
  margin: 5px 0;
  min-height: 209px;
  border-radius: 15px;
  background: #FFFFFF;
  outline: none;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
}

.editor {
  &__label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: inline-block;
  }

  a {
    color: blue;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}