@import '../../style/variables';

.users {

  .title {
    text-align: center;
    margin-bottom: 30px;
  }

  &__loader {
    display: block;
    margin-left: auto;
    width: 64px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__modal {
      .modal__text {
        margin-bottom: 60px;
    }
      .modal__btn-group {
        margin: 0 auto;
    }

    .modal__btn {
      margin: 0 auto;
      width: 100px;
    }
  }

  .users-table {
    width: 830px;
    margin: 0 auto;
  }

  .input {
    height: 50px;
    text-align: left;
  }

  table {
    margin-bottom: 20px;
    overflow: auto;
    padding-bottom: 30px;
    font-size: 14px;
    border-radius: 15px;
    height: 300px;
    border: 1px solid #F8F9F9;
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-column-gap: 10px;
    background-color: $white;
    grid-template-columns:
    minmax(240px, 240px)
    minmax(240px, 240px)
    minmax(125px, 125px)
    minmax(125px, 125px);
    grid-template-rows: 40px;
    grid-auto-rows: 30px;
    padding-left: 20px;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  td {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    height: 31px;
  }

  th, td {
    &:nth-child(3) {
      text-align: end;
    }

    &:nth-child(4) {
      text-align: end;
    }
  }

  th {
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    top: 0;
    background-color: $white;
    text-align: left;
    padding-top: 12px;
    overflow: hidden;
    white-space: nowrap;
  }



  .btn {
    cursor: pointer;
    color: $link;

    &:hover:not([disabled]) {
        border-bottom: 1px solid $link;
    }

    &[disabled] {
      color: $dark-grey;
      cursor: unset;
    }



    //&:hover {
    //
    //  //text-decoration: underline;
    //}
  }
}