@import '../../style/variables';

.checkbox {
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }

    input {
      &[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid #313132;
        outline: none;
        cursor: pointer;
      }

      &:checked {
        background-color: $coal;
        position: relative;
      }

      &:checked::before {
        content: '';
        width: 10px;
        height: 10px;
        background: url('../../assets/tick.svg') no-repeat center center/cover;
        color: #fff;
        position: absolute;
        left: 2px;
        top: 2px;
      }
    }
  }
}

