@import '../../style/variables';

.search {
  position: relative;
  display: flex;
  align-items: center;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  &__results {
    position: absolute;
    background-color: $white;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 324px;
    top: -56px;
    max-height: 145px;
    padding: 15px 10px;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    z-index: 1;

    span {
      display: inline-block;
      text-transform: uppercase;
      color: $coal;
      margin-bottom: 15px;
      font-weight: 500;
    }

    a {
      color: $link;
      margin-bottom: 10px;
    }
  }

  input {
    outline: none;
    border: none;
  }

  input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
    outline: none;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button {
    display: none;
  }

  input[type=search] {
    background: url('../../assets/search.svg') no-repeat 9px center;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
  }

  input[type=search]:focus {
    color: $grey;
  }

  input:-moz-placeholder {
    color: $grey;
  }

  #form input[type=search] {
    width: 268px;
    color: $grey;
    padding-left: 32px;
  }
}


@-webkit-keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}