@import '../../style/variables';

.input {
  position: relative;

  label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: inline-block;
  }

  input {
    border: none;
    height: 30px;
    border-bottom: 1px solid $grey;
    font-size: 12px;
    display: block;
    width: 100%;
    border-radius: 7.5px 7.5px 0 0;
    background-color: $white;
    padding: 6px 10px;

    &::placeholder {
      color: $coal;
    }

    &:focus {
      background-color: $white;
      outline: none;
      color: $coal;

      &::placeholder {
        color: $coal;
      }
    }
  }

  .error-text {
    margin-top: 5px;
    position: absolute;
    color: $alert;
    font-size: 8px;
    padding-left: 10px;
  }

  .error {
    &::placeholder {
      color: $alert;
    }

    color: $alert;
  }
}