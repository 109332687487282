@import '../../../style/variables';

.callreport {
  height: 900px;
  overflow: auto;

  &__block {
    td {
      padding: 0!important;
      margin: 0!important;
    }

    span {
      margin-bottom: 10px;
      display: inline-block;
    }

    h3 span {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 50px;
      display: inline-block;
    }

    hr {
      height: 2px;
      background-color: $medium-grey;
      border: none;
      margin: 30px 0px;
    }


  }
}

.amber {
  color: $amber;
}
.red {
  color: $alert;
}