@import '../../style/variables';

.management {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .title {
      margin-bottom: 0;
    }
  }

  &__btn-block {
    display: flex;
    justify-content: right;

    .btn {
      font-size: 14px;
      color: $link;
      outline: none;

      &:hover {
        border-bottom: 1px solid $link;
      }
    }
  }

  &__cards {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
  }

  &__chart {
    margin-bottom: 45px;
  }


  &__card {
    .card {
      &__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__title {
        margin-bottom: 11px;
      }

      &__number {
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
      }
    }

  }

  .redBorder {
    border: 1px solid $alert;
  }

  .green {
    border: 1px solid $green;
  }

  .blue {
    border: 2px solid $deep-blue;
  }

  .coal {
    border: 1px solid $coal;
  }

  .aqua {
    border: 1px solid $aqua;
  }

  .pink {
    border: 1px solid $pink;
  }

  .orange {
    border: 1px solid $orange;
  }

  &__table {
    .table {
      margin-bottom: 20px;
      overflow: auto;
      border-radius: 15px;
      height: 191px;
      border: 1px solid #F8F9F9;
      display: grid;
      border-collapse: collapse;
      min-width: 100%;
      grid-column-gap: 20px;
      background-color: $white;
      grid-template-columns:
        minmax(125px, 125px)
        minmax(125px, 125px)
        minmax(125px, 125px)
        minmax(125px, 125px)
        minmax(125px, 125px)
        minmax(125px, 125px)
        minmax(125px, 125px);
      grid-template-rows: 50px;
      grid-auto-rows: 30px;
      padding-left: 20px;

      & thead,
      tbody,
      tr {
        display: contents;
      }

      td {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }

      th {
        font-size: 14px;
        font-weight: 500;
        position: sticky;
        top: 0;
        background-color: $white;
        text-align: center;
        padding-top: 22px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

  }
}
