.applicant-data {

  &__form {
    .form {
      &__field {
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  &__details {
    margin: 30px 0;
  }

  &__btn-block {
    margin: 0 auto;
  }
}