@import '../../style/variables';

.fake-dropdown {

  &__label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: inline-block;
  }

  &__btn {
    border: none;
    border-bottom: 1px solid $grey;
    font-size: 12px;
    display: block;
    text-align: left;
    width: 200px;
    border-radius: 7.5px 7.5px 0 0;
    background-color: $white;
    padding: 6px 10px;
    position: relative;

    &:focus {
      background-color: $white;
      outline: none;
      color: $coal;
    }

    &::before {
      content: '';
      background: url('../../assets/chevdown.svg') no-repeat center center/cover;
      width: 9px;
      height: 7px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 12px;
    }

  }
}

