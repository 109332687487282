@import '../../../style/variables';

.card-modal {
  &__comment {
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: 500;
    font-size: 12px;
    color: $grey;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 20px;
  }
}