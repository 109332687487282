@import '../../style/variables';

.homepage {
  h1 {
    text-align: left;
  }

  &__card {
    min-height: 300px;
    height: 300px;
    max-height: 300px;

    .card {
      &__list {
        height: 150px;
        overflow:hidden;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        line-height: 21px;
        font-size: 14px;

        &:last-child {
          margin-bottom: 0;
        }

      }
    }
  }
    .card-customers {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      line-height: 21px;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        width: 360px;
        display: flex;
        justify-content: space-between;
      }
    }

    .cards {
      width: 400px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: space-between;
    }

    &__top-block, &__bottom-block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
    }

    &__cards {
      color: $white;
      display: flex;

      .cards {
        &__wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        &__number {
          font-weight: 500;
          font-size: 40px;
          line-height: 60px;
        }

        &__title {
          margin-bottom: 20px;
        }
      }

    }

    &__alerts {
      width: 830px;
      height: 300px;

      .alerts {
        &__table {
          display: block;
          margin-left: 6px;
          height: 150px;
          overflow: hidden;
        }

        &__body {
          height: 100px;
          max-height: 100px;
          overflow: auto;
          margin-bottom: 20px;
        }

        &__row {
          display: grid;
          grid-column-gap: 40px;
          grid-template-columns:
          minmax(10px, 10px)
          minmax(195px, 195px)
          minmax(100px, 100px)
          minmax(240px, 240px)
          minmax(100px, 100px);
          grid-template-rows: 30px;
          grid-auto-rows: 20px;
        }
      }

      .unread {
        margin-top: 3px;
        width: 10px;
        height: 10px;
        background-color: $link;
        border-radius: 50%;
        box-shadow: inset 0px 1px 3px rgba(85, 84, 84, 0.5);
      }
    }
  }