ol.steps {
  $line_height: 20px;
  $padding: 9px;
  $arrow_size: ($line_height + 2 * $padding)/2;
  $max_li: 10;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  text-transform: uppercase;
  line-height: $line_height;
  font-weight: bold;
  counter-reset: li;
  color: #fff;

  li {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
    text-align: center;
    position: relative;
    margin-left: 2 * $arrow_size;

    // Appended arrow.
    &:after {
      position: absolute;
      top: 50%;
      left: 100%;
      content: " ";
      height: 0;
      width: 0;
      pointer-events: none;
      border: solid transparent;
      border-width: $arrow_size;
      margin-top: -$arrow_size;
    }

    // No prepended arrow on first item.
    &:first-child {
      margin-left: 0;

      span {
        padding: $padding;

        &:after {
          border: none;
        }
      }
    }

    // No appended arrow on last item.
    &:last-child {
      &:after {
        border-width: 0;
      }
    }

    span {
      display: block;
      padding: $padding ($padding + $arrow_size) $padding $padding;

      // Prepended arrow inset.
      &:after {
        position: absolute;
        top: 50%;
        right: 100%;
        content: " ";
        height: 0;
        width: 0;
        pointer-events: none;
        border: solid #032338;
        border-left-color: transparent;
        border-width: $arrow_size;
        margin-top: -$arrow_size;
      }
    }
  }

  li {
    &:nth-child(1) {
      width: 15.5%;
      background-color: #270EBF;

      &:after {
        border-left-color: #270EBF;
      }
    }

    &:nth-child(2) {
      width: 27.5%;
      background-color: #FF2DFF;

      span {
        &:after {
          border: solid #FF2DFF;
          border-left-color: transparent;
          border-width: 19px;
        }
      }

      &:after {
        border-left-color: #FF2DFF;
      }
    }

    &:nth-child(3) {
      width: 27.5%;
      background-color: #FF1513;

      span {
        &:after {
          border: solid #FF1513;
          border-left-color: transparent;
          border-width: 19px;
        }
      }

      &:after {
        border-left-color: #FF1513;
      }
    }

    &:nth-child(4) {
      width: 20.5%;
      background-color: #FC9C38;

      span {
        &:after {
          border: solid #FC9C38;
          border-left-color: transparent;
          border-width: 19px;
        }
      }

      &:after {
        border-left-color: #FC9C38;
      }
    }

    &:nth-child(5) {
      width: 15.5%;
      background-color: #41DDFE;

      span {
        &:after {
          border: solid #41DDFE;
          border-left-color: transparent;
          border-width: 19px;
        }
      }

      &:after {
        border-left-color: #270EBF;
      }
    }
  }

  // For the overlapping to work, later LIs must have a lower z-index.
  @for $i from 1 through $max_li {
    li.step#{$i} {
      z-index: $max_li - $i;
    }
  }
}