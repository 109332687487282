@import '../../style/variables';

.customers-table {
  &__header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .title {
      margin-bottom: 0;
    }
  }

  &__table {
    &.table {
      margin-bottom: 20px;
    }
  }

  &__btn-block {
    font-size: 14px;
    text-align-last: end;
  }

  &__btn {
    color: $link;
  }
}