@import '../../style/variables';

.funded {
  &__header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin-bottom: 0;
    }
  }

  &__btn-block {
    font-size: 14px;
    text-align-last: end;

    .btn {
      color: $link;
    }
  }

  &__table {
    margin-bottom: 20px;
    overflow: auto;
    padding-bottom: 30px;
    font-size: 14px;
    border-radius: 15px;
    height: 620px;
    border: 1px solid #F8F9F9;
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-column-gap: 40px;
    background-color: $white;
    grid-template-columns:
    minmax(125px, 125px)
    minmax(125px, 125px)
    minmax(125px, 125px)
    minmax(125px, 125px)
    minmax(125px, 125px);
    grid-template-rows: 40px;
    grid-auto-rows: 30px;
    padding-left: 20px;

    & thead,
    tbody,
    tr {
      display: contents;
    }

    td {
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }

    th {
      font-size: 14px;
      font-weight: 500;
      position: sticky;
      top: 0;
      background-color: $white;
      text-align: center;
      padding-top: 12px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

