@import './style/variables';

html, body {
  background-color: #F5F5F5;
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > .container {
  width: 1260px;
  margin: 0 auto;
}


.App {
  &[data-theme='dark'] {
    background-color: $black;

    color: $white;

    h2 {
      color: $white;
    }
    .card {
      background-color: $coal;
    }

    & .title, .card-title {
      color: $white;
    }
  }

  &[data-theme='overlay'] {
    .app-overlay {
      position: absolute;
      top: 44px;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(49, 49, 50, 0.5);
      z-index: 1;
    }
  }
}