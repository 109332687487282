@import '../../style/variables';

.searchBar {

  &__form {
    width: 360px;
  }

  &__input {
    border: none;
    border-bottom: 1px solid $grey;
    font-size: 12px;
    display: block;
    width: 100%;
    border-radius: 7.5px 7.5px 0 0;
    padding: 6px 0;
    text-indent: 30px;
    background: url('../../assets/searchDark.svg') no-repeat 9px center;
    background-color: $white;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;

    &::placeholder {
      color: $coal;
    }

    &:focus {
      background-color: $white;
      outline: none;
      color: $coal;

      &::placeholder {
        color: $coal;
      }
    }
  }
}