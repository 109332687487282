.react-chained-modal-cover {
  background: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}