//colours
$navy: #092C4C;
$medium-grey: #CFCFD1;
$blue: #167FFC;
$green: #27AE60;
$light-green: #EBFFF7;
$pink: #FD3259;
$off-white: #FAFAFA;
$amber: #ffbf00;
$white: #FFFFFF;
$coal: #313132;
$dark-grey: #6E6E73;
$grey: #C8CACB;
$alert: #FF1513;
$bg: #F5F5F5;
$link: #0071E3;
$orange: #FC9C38;
$black: #000000;
$light-grey: #F8F9F9;
$deep-blue: #270EBF;
$aqua: #41DDFE;
$pink: #FF2DFF;
$green: #1EBF1B;


//font-sizes
$title: 68px;
$dash-stat: 136px;
$subtitle: 34px;
$h1: 34px;
$h2: 21px;
$h3: 16px;
$p: 16px;
$description: 12px;
$button: 14px;
$field: 14px;
$chart-label: 10px;
$table-heading: 16px;
$table-title: 14px;
$table-content: 12px;