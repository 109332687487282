@import '../../style/variables';

.metro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 90px;

  .img-wrapper {
    position: relative;
    margin: 0 auto;
  }

  .station {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    gap: 20px;
    margin: 0 auto;

    .name {
      text-align: end;
      width: 250px;
      color: $deep-blue;
    }

    .value {
      color: $coal;
      width: 250px;
      overflow-wrap: break-word;
    }

    .old {
      text-decoration: line-through;
    }

    .circle {
      height: 38px;
      width: 38px;
      border-radius: 50%;
      background-color: $white;
      border: 7px solid $coal;
    }
  }

  .station.inactive {
    .name {
      color: $grey;
    }

    .circle {
      border: 7px solid $grey;
    }
  }

  .station:nth-child(2) {
    top: -5px;
    left: -285px;
  }

  .station:nth-child(3) {
    top: -5px;
    right: -283px;
  }

  .station:nth-child(4) {
    top: 232px;
    left: -110px;
  }

  .station:nth-child(5) {
    top: 360px;
    left: -110px;
  }

  .station:nth-child(6) {
    top: 488px;
    left: -110px;
  }

  .station:nth-child(7) {
    top: 615px;
    left: -110px;
  }

  .station:nth-child(8) {
    top: 745px;
    left: -110px;
  }

  .station:nth-child(9) {
    top: 872px;
    left: -110px;
  }

  .station:nth-child(10) {
    top: 1000px;
    left: -110px;
  }

  .station:nth-child(11) {
    top: 1128px;
    left: -110px;
  }

  .station:nth-child(12) {
    top: 1255px;
    left: -110px;
  }

  .station:nth-child(13) {
    top: 1385px;
    left: -110px;
  }

  .station:nth-child(14) {
    top: 1512px;
    left: -110px;
  }

  .station:nth-child(15) {
    top: 1640px;
    left: -110px;
  }

  .station:nth-child(16) {
    top: 1768px;
    left: -110px;
  }

  .station:nth-child(17) {
    top: 1896px;
    left: -110px;
  }

  .station:nth-child(18) {
    top: 2000px;
    left: -110px;
  }
}
