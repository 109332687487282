@import '../../../style/variables';

.affordability {
  height: fit-content;

  &__block {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }

    &_total-sum {
      font-weight: 500;
      margin-bottom: 10px;
    }

    &_categories {
      margin-left: 20px;
    }

    &_item {
      &.green {
        color: $green;
      }

      &.red {
        color: $alert;
      }
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}